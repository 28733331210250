<template>
  <div class="consulting-detail">
      <div class="agent"
           :class="menuIndex==1?'agent-1':menuIndex==2?'agent-2':menuIndex==3?'agent-3':''"
      >
          <div class="text-box">
              <div class="name">{{textInfo[menuIndex].name}}</div>
              <div class="text">{{textInfo[menuIndex].info}}</div>
          </div>
          <div class="consulting-menu">
            <div :class="menuIndex==1?'active':''"
                 @click="menuIndex=1"
            >想代理</div>
            <div :class="menuIndex==2?'active':''"
                 @click="menuIndex=2"
            >想合作</div>
            <div :class="menuIndex==3?'active':''"
                 @click="menuIndex=3"
            >资料下载</div>
          </div>
      </div>
      <div class="agent-content" v-show="menuIndex==1">
        <div class="why-title">
          如何成为渠道合作伙伴？
        </div>
        <div class="why-step">
            <div class="step-item">
              <div>
                <img src="../../assets/yixiang-icon.png">
              </div>
              <div>
                填写合作意向表
              </div>
            </div>
            <div class="step-item">
            <div>
              <img src="../../assets/renzheng-icon.png">
            </div>
            <div>
              实名认证审核
            </div>
          </div>
            <div class="step-item">
            <div>
              <img src="../../assets/jiaoliu-icon.png">
            </div>
            <div>
              合作意向深入交流
            </div>
          </div>
            <div class="step-item">
            <div>
              <img src="../../assets/qianyue-icon.png">
            </div>
            <div>
              签约成为合作伙伴
            </div>
          </div>
            <div class="step-item">
            <div>
              <img src="../../assets/zhichi-icon.png">
            </div>
            <div>
            项目技术服务支持
            </div>
          </div>
        </div>
        <div class="title">
          渠道合作意向申请表
        </div>
        <el-form
            ref="agentInfo"
            :model="agentForm"
            :rules="agentRulesForm"
            key="agentInfo"
        >
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <el-form-item label="公司名称" prop="companyName">
                    <el-input v-model="agentForm.companyName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="联系人" prop="contactName">
                <el-input v-model="agentForm.contactName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <el-form-item label="所在城市" prop="city">
                <el-input v-model="agentForm.city"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="联系方式" prop="contactNumber">
                <el-input v-model="agentForm.contactNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <el-form-item label="意向合作领域" prop="areas">
                <el-input v-model="agentForm.areas"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="意向合作产品" prop="product">
                <el-input v-model="agentForm.product"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="agentForm.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="24">
              <el-form-item label="备注说明" prop="remark">
                <el-input
                    v-model="agentForm.remark"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-button class="btn-submit" @click="agentSubmit">
          一键开启AI之旅
        </el-button>
      </div>
      <div class="cooperation-content" v-show="menuIndex==2">
        <div class="title">
          技术产品供应申请表
        </div>
        <el-form
            ref="productInfo"
            :model="productForm"
            :rules="productRulesForm"
            key="productInfo"
        >
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <el-form-item label="公司名称" prop="companyName">
                <el-input v-model="productForm.companyName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="联系人" prop="contactName">
                <el-input v-model="productForm.contactName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <el-form-item label="所在城市" prop="city">
                <el-input v-model="productForm.city"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="联系方式" prop="contactNumber">
                <el-input v-model="productForm.contactNumber"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <el-form-item label="供应产品类型" prop="productType">
                <el-input v-model="productForm.productType"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="productForm.email"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="space-between">
            <el-col :span="24">
              <el-form-item label="备注说明" prop="remark">
                <el-input
                    v-model="productForm.remark"
                    type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-button class="btn-submit" @click="cooperationSubmit">
          确认提交
        </el-button>
      </div>
      <div class="download-content" v-show="menuIndex==3">
          <div class="title">
          资料下载中心
          </div>
        <div class="text" style="margin-bottom: 5px">此资料仅向代理商提供，如需下载，请先申请。</div>
        <div class="text">请扫描下方二维码下载您需要的资料</div>
          <div class="xiazaiCode" style="margin-top: 50px">
            <img src="../../assets/erweima.png" alt="" width="180px" height="200px">
          </div>
          <div>下载链接： <a target="_blank" href="http://m.witeyesz.com:9999">http://m.witeyesz.com:9999</a></div>
      </div>
  </div>
</template>

<script>
export default {
  name: "consulting-detail",
  data(){
    return {
      menuIndex:1,
      textInfo:{
        1:{
          name:'想代理-渠道合作',
          info:'期待与你共创AI新时代',
        },
        2:{
          name:'想合作-技术产品供应',
          info:'携手合作 服务百业',
        },
        3:{
          name:'资料下载',
          info:'了解更详细的方案介绍',
        }
      },
      agentForm:{
        companyName:'',
        contactName:'',
        city:'',
        contactNumber:'',
        areas:'',
        product:'',
        email:'',
        remark:'',
      },
      agentRulesForm:{
        companyName: [
          { required: true, message: "公司名称不能为空", trigger: "blur" }
        ],
        contactName: [
          { required: true, message: "联系人不能为空", trigger: "blur" }
        ],
        city: [
          { required: true, message: "所在城市不能为空", trigger: "blur" }
        ],
        contactNumber: [
          { required: true, message: "联系方式不能为空", trigger: "blur" }
        ],
        areas: [
          { required: true, message: "意向合作领域不能为空", trigger: "blur" }
        ],
        product: [
          { required: true, message: "意向合作产品不能为空", trigger: "blur" }
        ],
        email: [
          { required: true, message: "邮箱不能为空", trigger: "blur" }
        ],
      },
      productForm:{
        companyName:'',
        contactName:'',
        city:'',
        contactNumber:'',
        productType:'',
        email:'',
        remark:'',
      },
      productRulesForm:{
        companyName: [
          { required: true, message: "公司名称不能为空", trigger: "blur" }
        ],
        contactName: [
          { required: true, message: "联系人不能为空", trigger: "blur" }
        ],
        city: [
          { required: true, message: "所在城市不能为空", trigger: "blur" }
        ],
        contactNumber: [
          { required: true, message: "联系方式不能为空", trigger: "blur" }
        ],
        productType: [
          { required: true, message: "产品类型不能为空", trigger: "blur" }
        ],
        email: [
          { required: true, message: "邮箱不能为空", trigger: "blur" }
        ],
      },
    }
  },
  created() {
    if(this.$route.query.type){
      this.menuIndex=this.$route.query.type;
    }
  },
  methods:{
    agentSubmit(){
      this.$refs.agentInfo.validate(valid => {
        if (valid) {
          this.$post('/website/intention',this.agentForm).then((res)=>{
              this.msgSuccess(res.msg);
          }).catch((err)=>{
              this.msgError(err.msg);
          })
        }
      });
    },
    cooperationSubmit(){
      this.$refs.productInfo.validate(valid => {
        if (valid) {
          this.$post('/website/cooperation',this.productForm).then((res)=>{
            this.msgSuccess(res.msg);
          }).catch((err)=>{
            this.msgError(err.msg);
          })
        }
      });
    }
  },
}
</script>

<style scoped lang="scss">
.consulting-detail{
  .agent{
    position: relative;
    width: 100%;
    height: 507px;
    .consulting-menu{
      position: absolute;
      bottom: -24px;
      display: flex;
      color: #fff;
      width: auto;
      left: 50%;
      transform: translate(-50%,-50%);
      border-bottom: 2px solid transparent;
      >div{
        padding: 10px 20px;
        cursor: pointer;
      }
      .active{
        border-bottom: 2px solid #F2F2F2;
      }
    }
  }
  .agent-1{
    background-image: url("../../assets/daili.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .agent-2{
    background-image: url("../../assets/hezuo.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .agent-3{
    background-image: url("../../assets/ziliaoxiazai.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .text-box{
    color: #fff;
    margin-left: 20%;
    padding-top: 160px;
    .name{
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .text{
      font-size: 28px;
    }
  }
  .agent-content,.cooperation-content,.download-content{
    .title,.why-title{
      text-align: center;
      font-size: 24px;
      font-weight: 400;
      margin-top: 120px;
      margin-bottom: 50px;
    }
    .why-step{
      display: flex;
      justify-content: space-between;
    }
    width: 800px;
    margin: 0 auto;
    text-align: center;
    .btn-submit{
      font-size: 18px;
      font-weight: 400;
    }
    .text{
      font-size: 18px;
    }
  }
}
</style>
